export class CrewData {
    public Client: string;
    public ClientCrewContactNo: string = "";
    public ClientCrewDob: any;
    public ClientCrewEmailID: string = "";
    public ClientCrewID: number;
    public ClientCrewName: string = "";
    public ClientCrewGender: string = "Male";
    public ClientCrewNationality: string;
    public ClientCrewPassport: string;
    public ClientID: number;
    public CrewContactNo: any;
    public CrewDOB: number = null;
    public CrewDuties: any = "CPT";
    public CrewEmail: string = "";
    public CrewName: string = "";
    public Gender: string = "";
    public CrewNationality: any;
    public CrewPassport: string = "";
    public CrewPassportExpiy: any;
    public CrewPassportValid: any;
    public Duties: number;
    public PassportExpiry: number;
    public PassportRegistered: number;
    public flag: string;
    public crewid: any;
    public CrewStatus: any;
    public ClientCrewImageURL: any;
    public ImageURL: any;
    public callingCodes: string = "";
}
export class PassengersData {

    public Client: string;
    public ClientID: number;
    public ClientPassengerContactNo: string = "";
    public ClientPassengerDob: any;
    public ClientPassengerEmailID: string = "";
    public ClientPassengerID: number;
    public ClientPassengerName: string = "";
    public ClientPassengerNationality: string = "";
    public ClientPassengerPassport: string = "";
    public Gender: string = "";
    public DTRowId: number;
    public PassportExpiry: number;
    public PassportRegistered: number;
    public flag: string;
    public passengerid: any;
    public PassengerName: string = "";
    public PassengerGender: string = "Male";
    public PassengerContactNo: any;
    public PassengerEmailId: string = "";
    public PassengerDOB: number = null;
    public PassengerNationality: any;
    public PassengerPassport: string = "";
    public PassengerPassportValid: number;
    public PassengerPassportExpiy: number;
    public PassengerStatus: any;
    public ClientPassengerImageURL: any;
    public ImageURL: any;
    public PassengerDialCode: string = "";
    public callingCodes?: string = "";
}

export class PaxProfileData {
    public key?: any;
    public UID: any;
    public name: string;
    public ClientPassengerImageURL: string = '';
    public file?: any;
}
export class CrewProfiledata {
    public key?: any;
    public UID: any;
    public name: string;
    public ClientCrewImageURL: string = '';
    public file?: any;
}
export interface CharterContactNumber {
    isoCode: string;
    nationalNumber: string;
}

export class CharterPax {
    public CPID: string;
    public CharterUserID: string;
    public PassengerName: string;
    public PassengerGender: string = "Male";
    public ImageURL: any;
    public ContactNo: string;
    public EmailID: string;
    public DOB: string;
    public Nationality: string;
    public Passport: string;
    public PassportRegistered: string;
    public PassportExpiry: string;
    public flag: string;
    public callingCodes: string;
    public CharterContactNumber: CharterContactNumber;
    public Gender: string = "";
    public PassengerContactNo?: any;
}

