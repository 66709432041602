import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { queryParams } from '@app/shared/utils/query-params';
import { CharterUriConfig } from '../config/charter-url-config';

@Injectable({
  providedIn: 'root',
})
export class PassengerService {
  public apiType = ApiTypes;
  public charterData:any;
  public paxGender: string[] = ['Male', 'Female'];
  constructor(private serverConnectService: ServerConnectService) {}
  public getPassengerList() {
    return new Promise((resolve, reject) => {
      const databody = queryParams({
        type: 'list',
        lastid: 0,
      });
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.charterPassenger,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res.paxlist);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  public deleteuserdata(id) {
    return new Promise((resolve, reject) => {
      const databody = queryParams({
        type: 'delete',
        paxid: id,
      });
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.charterPassenger,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }
  public addPassenger(
    paxType:string,
    passengername,
    passengerGender,
    imageurl,
    contactno,
    emailid,
    dob,
    nationality,
    passport,
    validfrom,
    validto,
    callingcodes,
    cpid?:string
  ) {
    return new Promise((resolve, reject) => {
      const databody = queryParams({
        type: paxType,
        PassengerName: passengername,
        Gender: passengerGender,
        ImageURL: imageurl,
        ContactNo: contactno,
        EmailID: emailid,
        DOB: dob,
        Nationality: nationality,
        Passport: passport,
        ValidFrom: validfrom,
        ValidTo: validto,
        callingCodes: callingcodes,
        CPID:cpid
      });
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.charterPassenger,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  // public validName(input) {
  //   const inputregExp = /^[a-zA-Z.'\-\s_]+$/;
  //   return inputregExp.test(input);
  // }
  public validEmail(email) {
    const emailRegx =/^[\w-.]+(\+[\w-]+)*@([\w-]+\.)+[\w-]{2,}$/i;
    return emailRegx.test(email);
  }

  public choosePassenger(srid: string, sector: string, legId: string | number) {
    return new Promise((resolve) => {
      const databody =
        'enct=1&srid=' + srid + '&sector=' + sector + '&SRSecID=' + legId;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.choosePassengerCharter,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          this.charterData = data.ChoosePassengerCharter.aaData;
          resolve(this.charterData);
        })
        .catch((error) => {
          resolve('Error');
        });
    });
  }

  public addCharterPassanger(sector, addpassvalue, srid, legid) {
    return new Promise((resolve) => {
      const databody =
        'sector=' +
        sector +
        addpassvalue +
        '&SRID=' +
        srid +
        '&legid=' +
        legid
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.addPassengerCharter,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
}
