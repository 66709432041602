import { MessageConfig } from '@app/shared/constants/constant';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from '@app/shared/services/common.service';
import { CharterPax } from '@app/setup/crew/client-crew-model';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent implements OnInit {
  @Input() public bookingDetails: any;
  @Input() public tripId: any;
  public messageConfig = MessageConfig;
  public TodayDate: string;
  private currentDate = moment().format('YYYY-MM-DD');
  public expand : boolean = false;

  constructor(private router: Router,private commonService : CommonService) { }

  public ngOnInit() {
    this.TodayDate = this.currentDate;
  }

  public calculateAge(birthdayDate) {
    const todayDate = new Date();
    const birthDate = new Date(birthdayDate);
    const age = todayDate.getFullYear() - birthDate.getFullYear();
    return age;
  }

  public addPassenger(item) {
    const queryParams = {
      tripId: this.tripId,
      sector: item.sector,
      srSecId: item.legid
    }
    this.router.navigate(['/charter/charter-choose-passenger'], {
      queryParams,
    });
  }

  public editPassenger(pax: CharterPax) {
    this.commonService.showSpinnerLoader();
    pax.CPID = pax.CPID;
    pax.PassengerName = pax.PassengerName;
    pax.PassengerGender = pax?.Gender ? pax?.Gender?.charAt(0).toUpperCase() + pax?.Gender?.slice(1).toLowerCase() : pax.PassengerGender;
    const paxContactNo = this.commonService.getContactNumberFormate(
      pax.callingCodes,
      pax.ContactNo
    );
    pax.CharterContactNumber = paxContactNo;
    pax.EmailID = pax.EmailID;
    pax.DOB = pax.DOB;
    pax.Nationality = pax.Nationality;
    pax.Passport = pax.Passport;
    pax.PassportRegistered = pax.PassportRegistered;
    pax.PassportExpiry = pax.PassportExpiry;
    pax.ImageURL = pax.ImageURL;
    const navigationExtras: NavigationExtras = {
      state: {
        type: 'EditPassenger',
        passengerInformation: pax,
      },
    };
    this.router.navigate(
      ['/setup/charter-passenger/enter-passenger-details'],
      navigationExtras
    );
    this.commonService.dismissLoader();
  }
}
